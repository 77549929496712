import { FC, PropsWithChildren } from "react";
import { usePathname } from "next/navigation";
import Box from "@mui/material/Box";

import LayoutScripts from "../LayoutScripts";
import Alerts from "@/components/common/Alerts";
import AlertDialog from "@/components/common/AlertDialogs";
import Navbar from "@/components/layout/Navbar";
import DropdownWrapper from "./DropdownWrapper";
import Footer from "@/components/layout/Footer";
import MonumetricAd from "@/components/adverts/MonumetricAd";

const MobileLayout: FC<PropsWithChildren> = ({ children }) => {
  const pathname = usePathname() as string;
  const hideDropdown = [
    "/gallery",
    "/idea",
    "/quiz",
    "/category",
    "/question",
    "/subscribe",
  ].some((path) => pathname.startsWith(path));
  const isThread = pathname.includes("/idea/");

  return (
    <>
      <LayoutScripts />
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
          position: "relative",
        }}
      >
        <Alerts />
        <AlertDialog />
        <Navbar isMobile />
        <main
          id="main"
          style={{
            height: "100svh",
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <DropdownWrapper hideDropdown={hideDropdown} />
          <div
            style={{
              paddingTop: hideDropdown ? "1em" : "4em",
            }}
          >
            {children}
          </div>
        </main>
        {!isThread && <MonumetricAd variant="footer-in-screen" />}
        <Footer />
      </Box>
    </>
  );
};

export default MobileLayout;
